import styled from '@emotion/styled';
import { Pagination } from 'antd';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/layout';
import PostCard from '../components/post-card';
import PostCardSimple from '../components/post-card-simple';
import SEO from '../components/seo';
import { blogMenuLinks } from '../components/_config/menu-links';
import { contentBox, flexCenter } from '../components/_shared/styled-mixins';
import { StyledFullHeightSection } from '../components/_shared/styled-section';
import Icon from '../components/icon';

const StyledIconContainer = styled.div`
  ${contentBox}
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  position: relative;
  color: var(--title-color);
  margin-bottom: var(--space-sm);
  padding: 0;
  max-width: 800px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  background-color: var(--bg-color)
  border: none;
  box-shadow: none;
  cursor: pointer;
`;

const StyledPaginationContainer = styled.div`
  ${flexCenter};
  width: 100%;
  margin-top: 2.5rem;

  & ul.ant-pagination {
    cursor: pointer;
    display: flex;
    list-style: none;

    & > li {
      ${flexCenter};
      min-width: 1rem;
    }
    & > li.ant-pagination-item {
      font-size: 1.5rem;
      padding: 0 0.5rem;
    }
    & > li.ant-pagination-disabled > a {
      color: var(--disabled-color);
    }
    & > li.ant-pagination-item-active > a {
      text-decoration: underline;
    }

    & > li.ant-pagination-disabled > button {
      background-color: var(--disabled-color);
    }

    & button {
      margin-left: 1rem;
      margin-right: 1rem;
      cursor: pointer;
      height: 35px;
      width: 35px;
      border: none;
      transition: var(--transition-normal);
    }

    & button:hover {
      background-color: var(--primary-color);
    }
  }
`;
const Blog = ({ data }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [showSimplePostCard, setShowSimplePostCard] = React.useState(false);

  const onPaginationChange = (page) => {
    setCurrentPage(page);
  };

  let paginationSize = data.site.siteMetadata.paginationPageSize;
  let leftCursor = (currentPage - 1) * paginationSize;
  let rightCursor = leftCursor + paginationSize;

  return (
    <Layout menuLinks={blogMenuLinks}>
      <SEO title="Blog" />
      <StyledFullHeightSection>
        <StyledIconContainer>
          <a onClick={() => setShowSimplePostCard(!showSimplePostCard)}>
            {showSimplePostCard ? <Icon icon="eye" /> : <Icon icon="eye-slash" />}
          </a>
        </StyledIconContainer>
        {data.allMarkdownRemark.edges.slice(leftCursor, rightCursor).map(({ node }) => {
          const coverImage = node.frontmatter.cover_image ? node.frontmatter.cover_image.childImageSharp.fluid : null;

          if (showSimplePostCard) {
            return (
              <PostCardSimple
                key={node.frontmatter.title}
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                description={node.frontmatter.description}
                link={`/blog${node.fields.slug}`}
                tags={node.frontmatter.tags}
              />
            );
          } else {
            return (
              <PostCard
                key={node.frontmatter.title}
                coverImage={coverImage}
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                description={node.frontmatter.description}
                link={`/blog${node.fields.slug}`}
                tags={node.frontmatter.tags}
              />
            );
          }
        })}
        <StyledPaginationContainer>
          <Pagination
            pageSize={paginationSize}
            current={currentPage}
            onChange={onPaginationChange}
            total={data.allMarkdownRemark.edges.length}
          />
        </StyledPaginationContainer>
      </StyledFullHeightSection>
    </Layout>
  );
};

Blog.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Blog;

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/content/posts/" }, frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            tags
            date(formatString: "D MMMM, YYYY")
            description
            cover_image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
    site {
      siteMetadata {
        paginationPageSize
      }
    }
  }
`;
