import styled from '@emotion/styled';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import TagList from './tag-list';
import { contentBox } from './_shared/styled-mixins';

const StyledPostTags = styled.div`
  pointer-events: none;
  margin: 0rem 0 0;
  position: relative;
  z-index: 2;
`;

const StyledPublishingDate = styled.div`
  color: var(--body-color);
  font-size: 0.8rem;
  font-weight: 500;
`;
const StyledContent = styled.div`
  padding: 0 var(--space);
`;
const StyledTitle = styled.h2`
  color: var(--primary-color);
  margin: 1rem 0;
`;
const StyledDescription = styled.p`
  color: var(--body-color);
`;
const StyledPostCard = styled.article`
  ${contentBox}
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--title-color);
  margin-bottom: var(--space-sm);
  padding: 0;
  max-width: 800px;

  &:hover {
    box-shadow: 1px 1px 5px 0 #fff, 1px 1px 8px 0 var(--primary-color);
  }

  > a {
    text-decoration: none;
  }
`;

const PostCardSimple = ({ title, date, description, link, tags }) => {
  return (
    <StyledPostCard>
      <StyledContent>
        <Link to={link}>
          <StyledTitle>{title}</StyledTitle>
        </Link>
        <div style={{ display: 'flex' }}>
          <StyledPublishingDate>published on {date}</StyledPublishingDate>
        </div>
        <StyledPostTags>
          <TagList tags={tags} />
        </StyledPostTags>

        <StyledDescription>{description}</StyledDescription>
      </StyledContent>
    </StyledPostCard>
  );
};

PostCardSimple.propTypes = {
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default PostCardSimple;
